<template>
    <ion-page>
        <ThanhTieuDeVue>
            <template #before>
                <div
                    class="row align-center ml-3 mr-1"
                    v-if="chuyenDiGanDay && chuyenDiGanDay.maChuyenDi"
                >
                    <div
                        style="padding-top: 3px"
                        @click="
                            getDanhSachLichSuChuyenDi();
                            dialogChonLichSuChuyenDi = true;
                        "
                    >
                        <!-- width: inherit; 
                            text-align: center; -->
                        {{ ThongTinThemCuaChuyenDi }}
                    </div>
                    <DxButton
                        type="default"
                        styling-mode="outlined"
                        icon="mdi mdi-chevron-down"
                        class="ml-1"
                        @click="
                            getDanhSachLichSuChuyenDi();
                            dialogChonLichSuChuyenDi = true;
                        "
                    />
                </div>
            </template>
            <template #after>
                <DxButton
                    :active-state-enabled="false"
                    :focus-state-enabled="false"
                    :hover-state-enabled="false"
                    :text="SoLuongKhachDaMuaVe"
                    type="normal"
                    styling-mode="text"
                    width="24px"
                    height="24px"
                    class="nut-chuthich-style clear-rounder nut-trangthai text-xs-center mx-2"
                    :style="`background-color: rgb(112, 112, 112);border-radius:4px;`"
                    @click="
                        getDanhSachTrangThaiCho();
                        dialogChuThich = true;
                    "
                    v-if="
                        chuyenDiGanDay &&
                        chuyenDiGanDay.maChuyenDi &&
                        data.DanhSachTangXe.length > 0
                    "
                />
                <DxButton
                    type="default"
                    icon="mdi mdi-qrcode-scan"
                    class="custom-icon-QR mr-1"
                    width="34px"
                    height="34px"
                    @click="quetMa()"
                    v-if="chuyenDiGanDay && chuyenDiGanDay.maChuyenDi"
                />
                <!-- data.DanhSachTangXe.length > 0 -->
                <DxButton
                    v-if="$Core.AuthApi.TokenParsed.preferred_username != '0888888888'"
                    type="default"
                    icon="mdi mdi-help-circle-outline"
                    @click="MoTrangHuongDan"
                />
            </template>
        </ThanhTieuDeVue>
        <ion-content :scroll-events="false">
            <div
                v-if="!DaDangKySuDungVe"
                class="row pa-2 pt-3 justify-center align-center text-xs-center font-16 font-medium"
                style="height: calc(100vh - 48px - 36px); position: relative"
            >
                Bạn chưa đăng ký sử dụng dịch vụ Vé điện tử. Vui lòng liên hệ Công ty của
                bạn để được cập nhật!
            </div>
            <div v-else-if="!HienThiSoDoCho"></div>
            <div
                class="row pa-2 pt-3 justify-center align-center text-xs-center font-16 font-medium"
                style="height: calc(100vh - 48px - 36px); position: relative"
                v-else-if="!chuyenDiGanDay || !chuyenDiGanDay.maChuyenDi"
            >
                Không tìm thấy thông tin chuyến đi!
            </div>
            <div v-else-if="data.DanhSachTangXe.length > 0 && HienThiSoDoCho">
                <!-- <div
                    style="
                        position: absolute;
                        width: 36px;
                        z-index: 5;
                        height: calc(100vh - 36px - 48px);
                    "
                >
                    <ChuThichVue :key="tempCapNhatChuThich" />
                </div> -->
                <div style="padding: 16px 16px 0px 16px">
                    <div style="position: relative">
                        <!-- ====================================Sơ đồ chỗ========================================= -->
                        <div
                            style="
                                position: absolute;
                                top: -10px;
                                margin-left: auto;
                                margin-right: auto;
                                left: 0;
                                right: 0;
                                z-index: 10;
                                width: 128px;
                            "
                        >
                            <div
                                class="row justify-space-around"
                                v-if="data.DanhSachTangXe.length > 1"
                            >
                                <div
                                    v-for="(item, index) in data.DanhSachTangXe"
                                    :key="index"
                                    :style="`width: 24px;
                                            height: 24px;
                                            padding: 4px;
                                            text-align: center;
                                            border-radius:50px;
                                            ${
                                                !item.active
                                                    ? 'border:1px solid #707070'
                                                    : ''
                                            }`"
                                    :class="`${
                                        item.active
                                            ? 'primary--bg white--text'
                                            : 'white-bg'
                                    }`"
                                    @click="onTitleClick(item)"
                                >
                                    {{
                                        item.tenTang.replace("Tầng ", "").trim() ||
                                        item.tenTang
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="SoDoGhe">
                            <SoDoChoVue
                                @SoDoCho="soDoCho"
                                :usecheckbox="false"
                                :usebackground="false"
                                width="calc(100% - 16px)"
                                :ChoTheoSdt="data.DanhSachChoNgoiTheoSdt"
                                :reloadData="tempReLoadTangXe"
                                :TangXe="model.TangXe"
                                :ChoPhepThaoTac="ChoPhepThaoTac"
                                height="calc(100vh - 208px)"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Xe không có sơ đồ chỗ -->
            <div
                v-else-if="
                    chuyenDiGanDay.maChuyenDi &&
                    data.DanhSachTangXe.length == 0 &&
                    HienThiSoDoCho
                "
                style="height: calc(100vh - 36px - 48px)"
                class="row justify-center"
            >
                <!-- Không có dữ liệu -->
                <div class="xs12" style="position: relative">
                    <div
                        class="row justify-center pa-3 pb-2 mb-3 font-12 text-xs-center"
                        style="border-bottom: 1px solid #ccc"
                    >
                        Xe của bạn chưa có sơ đồ ghế trên hệ thống. <br />
                        Vui lòng liên hệ Công ty của bạn để được cập nhật!
                    </div>

                    <div
                        style="
                            width: 100%;
                            height: calc(100vh - 156px);
                            overflow-y: auto;
                            overflow-x: hidden;
                        "
                    >
                        <DanhSachVeKhongSoDoVue
                            :guidChuyenDi="guidChuyenDi"
                            @thongBaoLoi="thongBaoLoi"
                        />
                    </div>

                    <!-- <div class="row justify-center mt-3">
                        <DxButton
                            text="In vé"
                            type="default"
                            styling-mode="outlined"
                            icon="mdi mdi-printer"
                            width="144px"
                        />
                    </div> -->

                    <div
                        class="fab-float-button row justify-center align-center"
                        v-if="ChoPhepThaoTac"
                    >
                        <DxButton
                            text="Bán vé"
                            type="default"
                            styling-mode="text"
                            icon="mdi mdi-alpha-v-box"
                            @click="BanVeGhePhu(true)"
                        />
                    </div>
                </div>
            </div>
            <div v-else style="height: 80%" class="column justify-center align-center">
                <div style="opacity: 0.6">Lỗi hệ thống.</div>
                <div style="opacity: 0.6">Vui lòng thử lại!</div>
                <DxButton
                    text="Thử lại"
                    type="default"
                    styling-mode="contained"
                    class="mt-2"
                    @click="ThuLai()"
                />
            </div>
            <!-- <DxSpeedDialAction
                :visible="
                    hiddenFloatBtn &&
                    data.DanhSachTangXe.length > 0 &&
                    ChoPhepThaoTac == true
                "
                :index="1"
                icon="mdi mdi-bus-stop"
                label="Đến bến"
                @click="
                    ParamThongBao = {
                        state: 'Warning',
                        title: 'Xác nhận kết thúc chuyến đi',
                        message:
                            'Bạn có chắc chắn muốn kết thúc chuyến đi này?',
                    };
                    dialogKetThucChuyenDi = true;
                "
            /> -->
            <DxSpeedDialAction
                :visible="
                    hiddenFloatBtn &&
                    data.DanhSachTangXe.length > 0 &&
                    ChoPhepThaoTac == true
                "
                :index="1"
                icon="mdi mdi-ticket-confirmation"
                label="Bán vé ghế phụ"
                @click="BanVeGhePhu(false)"
            />
            <DxSpeedDialAction
                :visible="
                    hiddenFloatBtn &&
                    data.DanhSachTangXe.length > 0 &&
                    ChoPhepThaoTac == true
                "
                :index="2"
                icon="mdi mdi-seat-outline"
                label="Khách ngồi ghế phụ"
                @click="MoDanhSachBanVeGhePhu()"
            />

            <DxSpeedDialAction
                :visible="
                    hiddenFloatBtn &&
                    data.DanhSachTangXe.length > 0 &&
                    ChoPhepThaoTac == true
                "
                :index="4"
                icon="mdi hiddenBtn"
                :onContentReady="hiddenFloatButton"
                label=""
            />
        </ion-content>
        <!-- ====================================Popup xác nhận hành khách xuống xe ========================================= -->
        <PopupVue
            :height="!chinhSuaSoatVe ? 'auto' : ''"
            v-model:dialog="dialogHKXuongXe"
            title="Thông tin hành khách"
            classPopup="popup-padding-bottom-16 popup-padding-right-16 popup-padding-left-16"
            :fullWidth="false"
            :key="componentKey"
        >
            <template #content>
                <XacNhanHanhKhachXuongXeVue
                    :Data="data.ThongTinKhachHang"
                    ref="XacNhanHKXuongXe"
                    @rsSuaThongTinSoatVe="rsSuaThongTin"
                />
            </template>
            <template #buttonRight>
                <div v-if="!chinhSuaSoatVe" class="row justify-end align-center">
                    <!-- :class="`${chinhSuaSoatVe ? 'disabled--text' : ''}`" -->
                    <ion-button
                        color="primary"
                        fill="solid"
                        class="ml-3"
                        @click="xacNhanKhachXuongXe"
                        >Xuống xe
                    </ion-button>
                </div>

                <div v-else class="row justify-end">
                    <ion-button
                        color="primary"
                        fill="solid"
                        @click="
                            () => {
                                $refs.XacNhanHKXuongXe.suaThongTin();
                            }
                        "
                    >
                        Xác nhận
                    </ion-button>
                </div>
            </template>
            <template #buttonLeft>
                <ion-button
                    v-if="!chinhSuaSoatVe"
                    color="primary"
                    fill="clear"
                    @click="InLaiVe(data.ThongTinKhachHang)"
                >
                    In vé
                </ion-button>

                <ion-button
                    v-else
                    color="primary"
                    fill="clear"
                    @click="(chinhSuaSoatVe = false), (dialogHKXuongXe = false)"
                >
                    Hủy bỏ
                </ion-button>
            </template>
        </PopupVue>
        <!-- ====================================Popup Không thực hiện chuyến đi ========================================= -->
        <PopupVue
            height="auto"
            v-model:dialog="dialogHKKhongDi"
            title=""
            buttonTextLeft="Quay lại"
            :fullWidth="false"
            @close="xacNhanKhachLoChuyen"
        >
            <template #content>
                <ThongBaoVue
                    :message="ParamThongBao.message"
                    :state="ParamThongBao.state"
                    :title="ParamThongBao.title"
                />
            </template>
        </PopupVue>
        <!-- ====================================Popup thông tin soát vé ========================================= -->
        <PopupVue
            :height="!chinhSuaSoatVe ? 'auto' : ''"
            v-model:dialog="dialogSoatVe"
            title="Thông tin hành khách"
            :buttonTextRight="chinhSuaSoatVe ? 'Xác nhận' : 'Soát vé'"
            :buttonTextLeft="chinhSuaSoatVe ? 'Hủy Bỏ' : 'Hủy chuyến'"
            @close="soatVe"
            classPopup="popup-padding-bottom-16 popup-padding-right-16 popup-padding-left-16"
            :fullWidth="false"
            :key="componentKey"
            :heightScrollView="!chinhSuaSoatVe ? 'auto' : '288px'"
        >
            <template #content>
                <ThongTinSoatVeVue
                    :Data="data.ThongTinKhachHang"
                    ref="ThongTinSoatVe"
                    @rsSuaThongTinSoatVe="rsSuaThongTin"
                />
            </template>
        </PopupVue>
        <!-- ====================================Popup thông tin soát vé chuyến đi trước ========================================= -->
        <PopupVue
            v-model:dialog="dialogSoatVeChuyenDiTruoc"
            height="auto"
            title=""
            :fullWidth="false"
            :useButtonLeft="false"
            :useButtonRight="false"
            :column="false"
            heightScrollView="144px"
        >
            <template #content>
                <ThongTinSoatVeChuyenDiTruocVue
                    :ThongTinSoatVeChuyenDiTruoc="ThongTinSoatVeChuyenDiTruoc"
                    ref="ThongTinSoatVeChuyenDiTruoc"
                />
            </template>
        </PopupVue>
        <!-- ====================================Thông báo hủy thanh toán ========================================= -->
        <PopupVue
            height="100%"
            v-model:dialog="dialogThongBaoHuyThanhToan"
            title="Thông báo"
            buttonTextLeft="HỦY THANH TOÁN"
            buttonTextRight="TIẾP TỤC THANH TOÁN"
            directionButton="column reverse"
            typeButtonLeft="danger"
            classPopup="clear-rounder"
            orderRight="0"
            :showCloseButton="true"
            :fullWidth="false"
            @close="
                () => {
                    dialogThongBaoHuyThanhToan = false;
                    resetSoDoCho();
                }
            "
        >
            <template #content>
                <ThongBaoHuyThanhToanVue />
            </template>
        </PopupVue>
        <!-- ====================================Popup chú thích ========================================= -->
        <PopupVue
            height="auto"
            v-model:dialog="dialogChuThich"
            title=""
            :fullWidth="false"
            :useButtonLeft="false"
            :useButtonRight="false"
            :column="false"
            heightScrollView="176px"
        >
            <template #content>
                <PopupChuThichVue
                    :DanhSachTrangThaiChoNgoi="DanhSachTrangThaiChoNgoi"
                    :SoLuongVeBanTheoLoaiDiemBan="SoLuongVeBanTheoLoaiDiemBan"
                />
            </template>
        </PopupVue>
        <!-- ====================================Popup chọn chuyến đi ========================================= -->
        <PopupVue
            height="auto"
            v-model:dialog="dialogChonLichSuChuyenDi"
            title=""
            :fullWidth="false"
            :useButtonLeft="false"
            :useButtonRight="false"
            :column="false"
        >
            <template #content>
                <PopupChonLichSuChuyenDiVue
                    :DanhSachLichSuChuyenDi="DanhSachLichSuChuyenDi"
                    :guidChuyenDi="guidChuyenDi"
                    @ChonLichSuChuyenDi="ChonLichSuChuyenDi"
                />
            </template>
        </PopupVue>
        <!-- ==================================== Popup danh sách vé mua ghế phụ  ========================================= -->
        <PopupVue
            height="auto"
            v-if="ifDialogDanhSachGhePhu"
            v-model:dialog="dialogDanhSachGhePhu"
            title=""
            :fullWidth="false"
            :useButtonLeft="false"
            :useButtonRight="false"
            :column="false"
        >
            <template #content>
                <PopupDanhSachVeMuaGhePhuVue @thongBaoLoi="thongBaoLoi" />
            </template>
        </PopupVue>
        <!-- ====================================Popup Thông báo ========================================= -->
        <PopupVue
            height="auto"
            v-model:dialog="dialogThongBao"
            title=""
            :useButtonLeft="false"
            @close="
                () => {
                    dialogThongBao = false;
                }
            "
        >
            <template #content>
                <ThongBaoVue
                    :message="ParamThongBao.message"
                    :state="ParamThongBao.state"
                    :title="ParamThongBao.title"
                />
            </template>
        </PopupVue>
        <!-- ====================================Popup Xác nhận kết thúc chuyến đi ========================================= -->
        <PopupVue
            height="auto"
            v-model:dialog="dialogKetThucChuyenDi"
            title=""
            buttonTextLeft="Hủy"
            class="popup-padding-8"
            @close="xacNhanKetThucChuyenDi"
        >
            <template #content>
                <ThongBaoVue
                    :message="ParamThongBao.message"
                    :state="ParamThongBao.state"
                    :title="ParamThongBao.title"
                />
            </template>
        </PopupVue>

        <PopupVue
            height="auto"
            v-if="ifPopupXacNhanBanVuotSoVeToiDa"
            v-model:dialog="PopupXacNhanBanVuotSoVeToiDa"
            heightScrollView="100%"
            title=""
            :useButtonLeft="false"
            :useButtonRight="false"
            class="popup-padding-16"
        >
            <template #content>
                <NotificationVue
                    :Params="ParamXacNhanBanVuotSoVeToiDa"
                    @click="XacNhanBanVuotSoVeToiDa"
                />
            </template>
        </PopupVue>

        <!-- ====================================Toast thành công========================================= -->
        <DxToast
            v-model:visible="toast.visible"
            v-model:type="toast.type"
            :position="{
                my: 'top center',
                at: 'top center',
                offset: '0 15',
            }"
            class="toast-style"
        >
            <template #contentTemplate>
                <ToastContentVue :toast="toast" />
            </template>
        </DxToast>
    </ion-page>
</template>
<script>
import config from "devextreme/core/config";
import repaintFloatingActionButton from "devextreme/ui/speed_dial_action/repaint_floating_action_button";
import ThanhTieuDeVue from "../../src/components/_Common/ThanhTieuDe";
import SoDoChoVue from "../../src/components/SoDoCho.vue";
import ThongTinSoatVeVue from "../../src/components/ThongTinSoatVe";
import ThongBaoHuyThanhToanVue from "../../src/components/ThongBaoHuyThanhToan";
import ToastContentVue from "../../src/components/_Common/ToastContent";
import PopupVue from "@/components/_Common/Popup";
import ThongBaoVue from "../../src/components/_Common/ThongBao";
import XacNhanHanhKhachXuongXeVue from "../../src/components/XacNhanHanhKhachXuongXe";
import XacNhanHanhKhachXuongThucHienChuyenDiVue from "../../src/components/XacNhanHanhKhachXuongThucHienChuyenDi";
import ChuThichVue from "../../src/components/ChuThichDoc";
import PhuongTien, { ChoNgoi } from "../../class/PhuongTien";
import ChuyenDi from "../../class/ChuyenDi";
import DonHang from "../../class/DonHang";
import {
    DxTextBox,
    DxTabPanel,
    DxButton,
    DxToast,
    DxSpeedDialAction,
} from "devextreme-vue";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import {
    IonContent,
    IonPage,
    onIonViewWillEnter,
    IonButton,
    IonFab,
    IonFabButton,
    IonIcon,
    onIonViewWillLeave,
} from "@ionic/vue";
import { qrCodeOutline } from "ionicons/icons";
import PopupChuThichVue from "../components/Popup/PopupChuThich.vue";
import PopupChonLichSuChuyenDiVue from "../components/Popup/PopupChonLichSuChuyenDi.vue";
import DanhSachVeKhongSoDoVue from "../components/DanhSachVeKhongSoDo.vue";
import PopupDanhSachVeMuaGhePhuVue from "../components/Popup/PopupDanhSachVeMuaGhePhu.vue";
import ThongTinSoatVeChuyenDiTruocVue from "../components/Popup/ThongTinSoatVeChuyenDiTruoc.vue";
import NotificationVue from "@sonphat/common-v1/components/Notification.vue";
export default {
    components: {
        ThanhTieuDeVue,
        SoDoChoVue,
        ThongBaoVue,
        PopupVue,
        XacNhanHanhKhachXuongXeVue,
        XacNhanHanhKhachXuongThucHienChuyenDiVue,
        ChuThichVue,
        ThongTinSoatVeVue,
        ThongBaoHuyThanhToanVue,
        ToastContentVue,
        DxTextBox,
        DxTabPanel,
        DxButton,
        DxToast,
        IonContent,
        IonPage,
        IonButton,
        IonFab,
        IonFabButton,
        IonIcon,
        PopupChuThichVue,
        PopupChonLichSuChuyenDiVue,
        DxSpeedDialAction,
        DanhSachVeKhongSoDoVue,
        PopupDanhSachVeMuaGhePhuVue,
        ThongTinSoatVeChuyenDiTruocVue,
        NotificationVue,
    },
    data() {
        return {
            qrCodeOutline,
            dialogThongBaoHuyThanhToan: false,
            dialogKetThucChuyenDi: false,
            dialogHKXuongXe: false,
            dialogSoatVe: false,
            dialogHKKhongDi: false,
            dialogThongBao: false,
            dialogChuThich: false,
            dialogChonLichSuChuyenDi: false,
            dialogDanhSachGhePhu: false,
            ifDialogDanhSachGhePhu: false,
            dialogSoatVeChuyenDiTruoc: false,
            ThongTinSoatVeChuyenDiTruoc: {},
            hienThiTimKiem: false,
            tempViTriDaChon: null,
            tempReLoadTangXe: 0,
            tempCapNhatChuThich: 0,
            tempDoubleTap: [],
            tempTimeTap: 0,
            tenDiemDung: "",
            searchButton: [
                {
                    name: "search",
                    location: "after",
                    options: {
                        icon: "search",
                        type: "normal",
                        onClick: () => {
                            setTimeout(() => {
                                this.$refs.TimKiemTheoSoDienThoai.instance.focus();
                            }, 500);
                            this.hienThiTimKiem = !this.hienThiTimKiem;
                            if (!this.hienThiTimKiem) {
                                this.model.SoDienThoai = "";
                                this.timKiemTheoSoDienThoai(); //Bỏ tìm theo số điện thoại
                            }
                        },
                    },
                },
            ],
            model: { TangXe: 1, SoDienThoai: "" },
            data: {
                ChoDaChon: [],
                DanhSachTangXe: [{}],
                MaTranCho: [],
                ThongTinKhachHang: null,
                DanhSachChoNgoiTheoSdt: [],
                ThongTinVe: {
                    LinkTraCuu: "",
                    MaUngDung: "",
                    MaChuyenDi: "",
                    MaDatCho: "",
                },
            },
            DanhSachVeKhongSoDo: [],
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
            toast: { visible: false, message: "", type: "success" },
            ThongTinThemCuaChuyenDi: "",
            SoLuongKhachDaMuaVe: 0,
            DanhSachTrangThaiChoNgoi: [],
            SoLuongVeBanTheoLoaiDiemBan: [],
            DanhSachLichSuChuyenDi: [],
            guidChuyenDi: null,
            hiddenFloatBtn: true,
            HienThiSoDoCho: false,
            DaDangKySuDungVe: false,
            ChoDangChon: null,
            ifPopupXacNhanBanVuotSoVeToiDa: false,
            PopupXacNhanBanVuotSoVeToiDa: false,
            ParamXacNhanBanVuotSoVeToiDa: {
                state: "Warning",
                title: "Thông báo!",
                message_title: "Bạn đã bán hết số vé tối đa được phép tại chuyến đi này!",
                message: "Bạn có muốn bán vượt số vé tối đa?",
            },
        };
    },
    computed: {
        ...mapState({
            ToaDo: (state) => state.ThongTin.ToaDo,
        }),
        ...mapGetters("Ve", [
            "chinhSuaSoatVe",
            "duocQuetMa",
            "chuyenDiGanDay",
            "componentKey",
        ]),
        ...mapGetters("QrCode", ["dataQuetMa", "dangQuetMa"]),
        ChoPhepThaoTac() {
            if (this.guidChuyenDi && this.chuyenDiGanDay) {
                return this.guidChuyenDi == this.chuyenDiGanDay.guidChuyenDi;
            }
            return false;
        },
    },
    methods: {
        ...mapMutations("Ve", [
            "resetSoDoCho",
            "setComponentKey",
            "reloadSoDoCho",
            "setChinhSuaSoatVe",
        ]),
        ...mapMutations("QrCode", ["setDataQuetMa", "setDangQuetMa", "setDataQuetMa"]),
        ...mapActions("Ve", [
            "layChuyenDiGanDay",
            "layDiemXuongHienTaiCuaChoNgoiChuyenDi",
            "LayThongTinThemCuaChuyenDi",
            "LayThongTinChuyenDiTheoMaChuyenDi",
        ]),
        ...mapActions("QrCode", ["kiemTraQrQuetMa", "quetMaQR"]),

        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        quetMa() {
            // let a =
            //     "https://vedientu.sbus.vn/api/TraCuu/TraCuuVe?MaUngDung=SONPHATSTICKET&MaChuyenDi=TNG.22.03.0278&MaDatCho=220310041453473F0WS5";
            // let b =
            //     "******531|NGUYEN HONG SON|1996******|0|163656245302466339|<*<**<<**********";
            // let c =
            //     "091849531|NGUYEN HONG SON|1996-04-21|0|163656245302466339|<1<00<<0973802338";
            // this.setDataQuetMa(a);
            // this.setDangQuetMa();
            this.quetMaQR();
        },
        async dieuHuong(cho) {
            this.ChoDangChon = cho;
            if (cho.TrangThai === parseInt(this.$i18n.t("trangThai.DaNgoi"))) {
                let rs = await this.layThongTinKhachHang();
                // await this.layDiemXuongHienTaiTheoCho();
                if (!rs) {
                    return;
                }
                this.dialogHKXuongXe = true;
                this.setComponentKey();
            }
            if (
                cho.TrangThai === parseInt(this.$i18n.t("trangThai.Trong")) &&
                this.ChoPhepThaoTac == true &&
                cho.LoaiCho !== parseInt(this.$i18n.t("LoaiCho.LaiXe"))
            ) {
                let rsKiemTraChuyenDi = await this.$store.dispatch(
                    "ChuyenDi/KiemTraTrangThaiChuyenDi",
                    {
                        IdChuyenDi: this.chuyenDiGanDay.guidChuyenDi,
                        SoKhach: 1,
                    },
                );
                if (!rsKiemTraChuyenDi.data) {
                    if (
                        this.$Helper.KiemTraQuyen(
                            this.$t("QuyenBanVe.BanVeTaiBen"),
                            this.$t("QuyenBanVe.BanVeVuotSoVeToiDa"),
                        ) &&
                        rsKiemTraChuyenDi.errorCode == 1268
                    ) {
                        this.showPopup("PopupXacNhanBanVuotSoVeToiDa");
                        return;
                    }
                    this.$Helper.ThongBaoPopup({
                        type: "Error",
                        message_title: "Bán vé gặp lỗi!",
                        message: rsKiemTraChuyenDi.message,
                    });
                    return;
                }
                this.ChuyenTrangBanVe();
            }
            if (
                cho.TrangThai === parseInt(this.$i18n.t("trangThai.DaMua")) &&
                this.ChoPhepThaoTac == true
            ) {
                let rs = await this.layThongTinKhachHang();
                if (!rs) {
                    return;
                }
                this.dialogSoatVe = true;
            }
        },
        XacNhanBanVuotSoVeToiDa(bool) {
            if (bool == true) {
                this.ChuyenTrangBanVe();
                this.PopupXacNhanBanVuotSoVeToiDa = false;
            } else if (bool == false) {
                this.PopupXacNhanBanVuotSoVeToiDa = false;
            }
        },
        ChuyenTrangBanVe() {
            this.hiddenFloatBtn = false;
            let query = { ...this.chuyenDiGanDay };
            query.GuidChoNgoi = this.ChoDangChon.id;
            this.$router.push({
                path: "/ban-ve-tren-xe",
                query: query,
            });
        },
        BanVeGhePhu(KhongCoSoDoGhe) {
            this.hiddenFloatBtn = false;
            let query = { ...this.chuyenDiGanDay };
            query.KhongCoSoDoGhe = KhongCoSoDoGhe;
            query.GuidChoNgoi = "00000000-0000-0000-0000-000000000000";
            this.$router.push({
                path: "/ban-ve-ghe-phu",
                query: query,
            });
        },
        MoDanhSachBanVeGhePhu() {
            // this.layDanhSachMuaVeGhePhu();
            this.ifDialogDanhSachGhePhu = true;
            this.dialogDanhSachGhePhu = true;
        },
        onTitleClick(item) {
            this.data.DanhSachTangXe.forEach((e) => {
                if (e.idTang == item.idTang) {
                    e.active = true;
                } else {
                    e.active = false;
                }
            });
            this.chonTangXe(item);
        },
        soDoCho(item, data) {
            this.dieuHuong(item);
        },
        async layDiemXuongHienTaiTheoCho() {
            try {
                let vtdc = await new ChoNgoi().viTriDangChon(true);
                let rs = await this.layDiemXuongHienTaiCuaChoNgoiChuyenDi({
                    guidChuyenDi: this.chuyenDiGanDay.guidChuyenDi,
                    guidChoNgoi: vtdc.id,
                });
                if (rs.StatusCode == 200) {
                    this.tenDiemDung = rs.Data.data.tenDiemDung;
                }
            } catch (error) {}
        },
        async layDanhSachTangXe() {
            //Lấy ds tầng xe
            try {
                this.data.DanhSachTangXe = [];
                let rs = await new PhuongTien().layDanhSachTangXe(this.guidChuyenDi);
                this.HienThiSoDoCho = true;
                if (rs.Data.status === true) {
                    let DanhSachTangXe = rs.Data.data;
                    if (DanhSachTangXe.length > 0) {
                        DanhSachTangXe.forEach((e) => {
                            e.text = e.tenTang;
                            e.id = e.idTang;
                        });
                        this.model.TangXe = DanhSachTangXe[0];
                        DanhSachTangXe[0].active = true;
                        this.data.DanhSachTangXe = DanhSachTangXe;
                        this.chonTangXe(DanhSachTangXe[0]);
                        this.reloadSoDoCho();
                        this.getDanhSachTrangThaiCho();
                    }
                } else {
                    this.data.DanhSachTangXe = [];
                }
            } catch (error) {
                this.thongBaoLoi("", error);
            }
        },
        chonTangXe(item) {
            this.tempReLoadTangXe++;
            this.model.TangXe = item;
            this.reloadSoDoCho();
            this.drawer = false;
            this.tempViTriDaChon = false;
        },
        async layThongTinKhachHang() {
            //Lấy thông tin khách hàng để hiển thị lên khi chọn các chức năng
            let viTriDangChon = await new ChoNgoi().viTriDangChon(true);
            if (!viTriDangChon) {
                this.thongBaoLoi("Đã xảy ra lỗi viTriDangChon. Vui lòng thử lại!");
                return false;
            }
            try {
                let ttKhachHangTheoChoNgoi = await new ChuyenDi().layThongTinHKQuaChoNgoi(
                    this.ThongTinThemCuaChuyenDi,
                    viTriDangChon.id,
                );
                if (!ttKhachHangTheoChoNgoi.Data.status) {
                    this.resetSoDoCho();
                    this.thongBaoLoi(ttKhachHangTheoChoNgoi.Data.message);
                    return false;
                }
                this.data.ThongTinKhachHang = ttKhachHangTheoChoNgoi.Data.data;
            } catch (error) {
                this.thongBaoLoi("", error);
                return false;
            }
            return viTriDangChon;
        },
        async layThongTinKhachHangQrCode() {
            try {
                let ttKhachHang = await new ChuyenDi().layThongTinHKQuaQrCode(
                    this.data.ThongTinVe.MaChuyenDi,
                    this.data.ThongTinVe.MaDatCho,
                );
                if (!ttKhachHang) {
                    return this.thongBaoLoi();
                }
                if (!ttKhachHang.Data.status) {
                    return this.thongBaoLoi(ttKhachHang.Data.message);
                }
                this.data.ThongTinKhachHang = ttKhachHang.Data.data;
                this.dialogSoatVe = true;
            } catch (error) {
                this.thongBaoLoi("", error);
            }
        },
        async layThongTinChuyenDiTheoMaChuyenDi() {
            try {
                let rs = await this.LayThongTinChuyenDiTheoMaChuyenDi({
                    MaChuyenDi: this.data.ThongTinVe.MaChuyenDi,
                });
                if (rs.Data.status == true) {
                    this.ThongTinSoatVeChuyenDiTruoc = rs.Data.data;
                    this.dialogSoatVeChuyenDiTruoc = true;
                } else {
                    this.thongBaoLoi("", rs.Data.message);
                }
            } catch (error) {
                this.thongBaoLoi("", error);
            }
        },
        rsSuaThongTin(statusCode) {
            if (statusCode == 200) {
                this.toast = {
                    message: "Sửa thông tin thành công",
                    type: "success",
                    visible: true,
                };
            } else {
                this.toast = {
                    message: "Sửa thông tin thất bại",
                    type: "error",
                    visible: true,
                };
            }
            this.reloadSoDoCho();
            this.dialogSoatVe = false;
            this.dialogHKXuongXe = false;
        },
        async soatVe(param) {
            //Đang chỉnh sửa
            if (this.chinhSuaSoatVe) {
                if (!param) {
                    this.dialogSoatVe = false;
                    return;
                }
                this.$refs.ThongTinSoatVe.suaThongTin();
                return;
            }
            //Không chỉnh sửa
            if (!param) {
                //Hủy chuyến
                this.resetSoDoCho();
                this.dialogSoatVe = false;
                this.ParamThongBao = {
                    state: "Warning",
                    title: "Xác nhận hủy chuyến",
                    message: "Bạn có chắc chắn muốn hủy chuyến đi này?",
                };
                this.dialogHKKhongDi = true;
                return;
            }
            try {
                //Soát vé
                let chuyenDi = new ChuyenDi();
                chuyenDi.guidXe = this.chuyenDiGanDay.guidXe;
                chuyenDi.maChuyenDi = this.chuyenDiGanDay.maChuyenDi;
                let ketQuaSoatVe = await chuyenDi.soatVe(
                    this.data.ThongTinKhachHang.maDatCho,
                    this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                );
                if (!ketQuaSoatVe.Data.status) {
                    this.dialogSoatVe = false;
                    return this.thongBaoLoi(ketQuaSoatVe.Data.message);
                }
                this.toastThanhCong("Soát vé thành công");
                this.dialogSoatVe = false;
                this.capNhatTrang();
            } catch (error) {
                this.thongBaoLoi("", error);
            }
        },
        async soatVeBangMaQr(param) {
            if (!param) {
                this.dialogSoatVe = false;
                return;
            }
            try {
                let chuyenDi = new ChuyenDi();
                chuyenDi.maChuyenDi = this.data.ThongTinVe.MaChuyenDi;
                chuyenDi.guidXe = this.chuyenDiGanDay.guidXe;
                let ketQuaSoatVe = await chuyenDi.soatVeBangMaQr(
                    this.data.ThongTinVe.MaDatCho,
                    this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                );
                if (!ketQuaSoatVe.Data.status) {
                    return this.thongBaoLoi(ketQuaSoatVe.Data.message);
                }
                this.dialogSoatVe = false;
                this.toastThanhCong("Soát vé thành công");
            } catch (error) {
                this.thongBaoLoi("Đã xảy ra lỗi. Vui lòng thử lại", error);
            }
        },
        async timKiemTheoSoDienThoai() {
            try {
                if (!this.model.SoDienThoai) {
                    this.data.DanhSachChoNgoiTheoSdt = [];
                    this.tempReLoadTangXe++;
                    this.reloadSoDoCho();
                    this.hienThiTimKiem = false;
                    return;
                }
                let choNgoiTheoSdt = await new ChuyenDi().layDanhSachChoNgoiTheoSoDienThoai(
                    this.chuyenDiGanDay.maChuyenDi,
                    this.model.SoDienThoai,
                );
                if (!choNgoiTheoSdt.Data.status) {
                    this.model.SoDienThoai = "";
                    this.hienThiTimKiem = false;
                    return this.thongBaoLoi(choNgoiTheoSdt.Data.message);
                }
                this.data.DanhSachChoNgoiTheoSdt = choNgoiTheoSdt.Data.data;
                this.tempReLoadTangXe++;
                this.reloadSoDoCho();
            } catch (error) {
                this.thongBaoLoi("", error);
                this.hienThiTimKiem = false;
            }
        },
        async xacNhanKhachXuongXe(param) {
            if (!param) {
                this.resetSoDoCho();
                this.dialogHKXuongXe = false;
                return;
            }
            try {
                let chuyenDi = new ChuyenDi();
                chuyenDi.guidXe = this.chuyenDiGanDay.guidXe;
                chuyenDi.maChuyenDi = this.chuyenDiGanDay.maChuyenDi;
                let ketQua = await chuyenDi.xacNhanKhachXuongXe(
                    this.data.ThongTinKhachHang.maDatCho,
                    this.ThongTinThemCuaChuyenDi,
                    this.data.ThongTinKhachHang.soGhe,
                    this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                );
                if (!ketQua.Data.status) {
                    this.dialogHKXuongXe = false;
                    return this.thongBaoLoi(ketQua.Data.message);
                }
                this.dialogHKXuongXe = false;
                this.toastThanhCong("Xác nhận hành khách xuống xe thành công");
                this.capNhatTrang();
            } catch (error) {
                this.dialogHKXuongXe = false;
                this.thongBaoLoi("", error);
            }
        },
        async xacNhanKhachLoChuyen(param) {
            if (!param) {
                this.resetSoDoCho();
                this.dialogHKKhongDi = false;
                return;
            }
            try {
                let chuyenDi = new ChuyenDi();
                chuyenDi.guidXe = this.chuyenDiGanDay.guidXe;
                chuyenDi.maChuyenDi = this.chuyenDiGanDay.maChuyenDi;
                let ketQua = await chuyenDi.xacNhanKhachLoChuyen(
                    this.data.ThongTinKhachHang.maDatCho,
                    this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                );
                if (!ketQua.Data.status) {
                    this.dialogHKKhongDi = false;
                    return this.thongBaoLoi(ketQua.Data.message);
                }
                this.dialogHKKhongDi = false;
                this.toastThanhCong("Xác nhận hành khách lỡ chuyến thành công");
                this.capNhatTrang();
            } catch (error) {
                this.dialogHKKhongDi = false;
                return this.thongBaoLoi("", error);
            }
        },
        async xacNhanKetThucChuyenDi(param) {
            if (!param) {
                this.dialogKetThucChuyenDi = false;
                return;
            }
            try {
                let chuyenDi = new ChuyenDi();
                chuyenDi.guidChuyenDi = this.chuyenDiGanDay.guidChuyenDi;
                let xacNhan = await chuyenDi.xacNhanKetThucChuyenDi();
                if (!xacNhan.Data.status) {
                    this.dialogKetThucChuyenDi = false;
                    return this.thongBaoLoi(xacNhan.Data.message);
                }
                this.dialogKetThucChuyenDi = false;
                this.toastThanhCong("Kết thúc chuyến đi thành công");
                this.capNhatTrang();
                setTimeout(() => {
                    this.$router.push("tabs/tab1");
                }, 1000);
            } catch (error) {
                return this.thongBaoLoi("", error);
            }
        },
        thongBao(state, title, message) {
            this.ParamThongBao = {
                state: state,
                title: title,
                message: message,
            };
            this.dialogThongBao = true;
        },
        toastThanhCong(message) {
            this.toast = {
                message: message,
                type: "success",
                visible: true,
            };
        },
        thongBaoLoi(message, log) {
            console.log(
                "🚀 ~ file: so-do-cho.vue ~ line 643 ~ thongBaoLoi ~ message, log",
                message,
                log,
            );
            this.dialogHKKhongDi = false;
            this.dialogHKXuongXe = false;
            this.dialogSoatVe = false;
            this.dialogKetThucChuyenDi = false;
            this.thongBao("Error", message || "Đã xảy ra lỗi. Vui lòng thử lại");
            this.capNhatTrang();
        },
        capNhatTrang() {
            this.tempCapNhatChuThich++;
            this.resetSoDoCho();
            this.tempReLoadTangXe++;
            this.reloadSoDoCho();
        },
        setThongTinVe() {
            setTimeout(async () => {
                if (!this.dataQuetMa) return;
                let dataQuetMa = this.$Helper.DecodeQrData_URL(this.dataQuetMa);
                try {
                    // let rs = await this.kiemTraQrQuetMa();
                    // if (!rs.check) {
                    //     throw new Error("Mã QR không hợp lệ!");
                    // }
                    this.data.ThongTinVe.MaUngDung = dataQuetMa.MaUngDung;
                    this.data.ThongTinVe.MaChuyenDi = dataQuetMa.MaChuyenDi;
                    this.data.ThongTinVe.MaDatCho = dataQuetMa.MaDatCho;
                    if (
                        this.data.ThongTinVe.MaChuyenDi == this.chuyenDiGanDay.maChuyenDi
                    ) {
                        this.layThongTinKhachHangQrCode();
                    } else {
                        this.layThongTinChuyenDiTheoMaChuyenDi();
                    }
                    this.setDataQuetMa(null);
                } catch (error) {
                    this.toast = {
                        message: "Mã QR không hợp lệ!",
                        type: "error",
                        visible: true,
                    };
                }
            }, 500);
        },
        async getThongTinThemChuyenDi() {
            let space = `\xa0\xa0\xa0\xa0\xa0\xa0`;
            try {
                let rs = await this.LayThongTinThemCuaChuyenDi({
                    GuidChuyenDi: this.guidChuyenDi,
                });
                if (!rs.Data.status) {
                    this.toast = {
                        message: rs.Data.message,
                        type: "error",
                        visible: true,
                    };
                } else {
                    let data = rs.Data.data;
                    // this.ThongTinThemCuaChuyenDi = `${space}${
                    //     data.tenBenDi
                    // } - ${data.tenBenDen} (${this.$Helper.formatDateTime(
                    //     data.thoiGianKhoiHanh
                    // )})${space}`;

                    this.ThongTinThemCuaChuyenDi = data.maChuyenDi;
                    // let marquee = document.querySelectorAll("marquee");
                    // if (marquee.length > 0) {
                    //     marquee[0].stop();
                    // }
                }
            } catch (error) {
                this.toast = {
                    message: error,
                    type: "error",
                    visible: true,
                };
            }
        },
        async getDanhSachTrangThaiCho() {
            //Lấy ds trạng thái chỗ
            let DanhSachTrangThaiCho = await new ChoNgoi().layDanhSachTrangThaiCho(
                this.guidChuyenDi,
            );
            let SoLuongVeBanTheoLoaiDiemBan = await new DonHang().laySoLuongVeBanTheoLoaiDiemBan(
                this.guidChuyenDi,
            );
            this.SoLuongVeBanTheoLoaiDiemBan = SoLuongVeBanTheoLoaiDiemBan || [];
            if (DanhSachTrangThaiCho.length > 0) {
                this.DanhSachTrangThaiChoNgoi = DanhSachTrangThaiCho || [];
                this.SoLuongKhachDaMuaVe =
                    DanhSachTrangThaiCho.filter(
                        (e) => e.idTrangThai == this.$t("trangThai.DaMua"),
                    )[0].soLuong + "" || 0 + "";
            } else {
                this.SoLuongKhachDaMuaVe = 0 + "";
            }
        },
        async getDanhSachLichSuChuyenDi() {
            //Lấy ds trạng thái chỗ
            let DanhSachLichSuChuyenDi = await new ChuyenDi().layDanhSachLichSuChuyenDi(
                this.chuyenDiGanDay.guidChuyenDi,
            );
            this.DanhSachLichSuChuyenDi = DanhSachLichSuChuyenDi || [];
        },
        async ChonLichSuChuyenDi(guidChuyenDi) {
            this.HienThiSoDoCho = false;
            this.dialogChonLichSuChuyenDi = false;
            this.guidChuyenDi = guidChuyenDi;
            this.layDanhSachTangXe();
            this.getThongTinThemChuyenDi();
        },
        InLaiVe(ThongTinKhachHang) {
            if (ThongTinKhachHang.daPhatHanh == false) {
                let query = {
                    guidChuyenDi: this.guidChuyenDi,
                    maDatCho: ThongTinKhachHang.maDatCho,
                };
                this.PhatHanhHoaDon(query, ThongTinKhachHang.maDatCho);
            } else {
                this.layThongTinVe(ThongTinKhachHang.maDatCho);
            }
        },
        async PhatHanhHoaDon(query, maDatCho) {
            try {
                let donHang = new DonHang();
                let rs = await donHang.phatHanhVeTheoMaDatCho(query);
                if (!rs.Data.status) {
                    return this.thongBaoLoi(rs.Data.message);
                }
            } catch (error) {
                this.thongBaoLoi("", error);
            }
            this.layThongTinVe(maDatCho);
        },
        async layThongTinVe(maGiaoDich) {
            try {
                let rs = await this.$AppClass.InVe(
                    this.$i18n.t("url.InVeTheoMaDatCho"),
                    "maDatCho",
                    maGiaoDich,
                );
                if (rs == "error") {
                    return this.thongBaoLoi("error", "In thất bại! ");
                }
                this.$stopLoading;
            } catch (error) {
                this.$stopLoading;
                this.error = true;
                console.error(error);
            }
        },

        hiddenFloatButton() {
            let elm = document.querySelectorAll(".hiddenBtn");
            if (elm.length > 0) {
                setTimeout(() => {
                    elm.forEach((e) => {
                        e.parentElement.parentElement.style.display = "none";
                    });
                }, 10);
            }
        },
        toastThongBao(type, message) {
            this.toast = {
                message: message,
                type: type,
                visible: true,
            };
        },
        getData() {
            if (this.chuyenDiGanDay && this.chuyenDiGanDay.maChuyenDi) {
                this.guidChuyenDi = this.chuyenDiGanDay.guidChuyenDi;
                this.layDanhSachTangXe();
                this.getThongTinThemChuyenDi();
            } else {
                this.HienThiSoDoCho = true;
                let toast = {
                    message: "Không tìm thấy chuyến đi!",
                    type: "success",
                    visible: true,
                };
                this.emitter.emit("onToast", toast);
            }
        },
        closeAllDialog() {
            this.dialogThongBaoHuyThanhToan = false;
            this.dialogKetThucChuyenDi = false;
            this.dialogHKXuongXe = false;
            this.dialogSoatVe = false;
            this.dialogHKKhongDi = false;
            this.dialogThongBao = false;
            this.dialogChuThich = false;
            this.dialogChonLichSuChuyenDi = false;
            this.dialogDanhSachGhePhu = false;
        },
        async ThuLai() {
            await this.layChuyenDiGanDay();
            this.getData();
        },
        MoTrangHuongDan() {
            this.$router.push({
                path: "/huong-dan-su-dung/Xem-Chi-Tiet-Huong-Dan",
                query: {
                    name: this.$i18n.t("UrlHuongDan.BanVeChoLaiXe"),
                },
            });
        },
        NgatDOMPopup(key) {
            setTimeout(() => {
                this[key] = false;
            }, 300);
        },
        async LayChiTietCauHinhDoanhNghiepTheoMaCauHinh() {
            try {
                this.$startLoading;
                await this.$store.dispatch(
                    "DVVTLenh/LayChiTietCauHinhDoanhNghiepTheoMaCauHinh",
                    {
                        DanhSachMaCauHinhCanLay: [
                            this.$t(
                                "MaCauHinhDoanhNghiep.DoanhNghiepSuDungHoaDonBanHangCoGiamTru",
                            ),
                        ],
                    },
                );
            } catch (error) {
                console.log("🚀 ~ error:", error);
            } finally {
                this.$stopLoading;
            }
        },
    },
    created() {
        onIonViewWillEnter(async () => {
            this.DaDangKySuDungVe = false;
            this.HienThiSoDoCho = false;
            this.hiddenFloatBtn = true;
            this.LayChiTietCauHinhDoanhNghiepTheoMaCauHinh();
            this.setChinhSuaSoatVe(false);
            localStorage.removeItem("ChoDaChon");
            let SuDungDichVu = this.$Core.AuthApi.TokenParsed.SuDungDichVu || null;
            if (SuDungDichVu) {
                let arrDichVu = SuDungDichVu.split(";");
                arrDichVu.forEach(async (e) => {
                    if (e == this.$t("StringCheck.SuDungVe")) {
                        this.DaDangKySuDungVe = true;
                        await this.layChuyenDiGanDay();
                        this.getData();
                    }
                });
            } else {
                await this.layChuyenDiGanDay();
                this.getData();
            }
        });
    },
    mounted() {
        config({
            floatingActionButtonConfig: {
                icon: "mdi mdi-menu",
                position: {
                    at: "right bottom",
                    my: "right bottom",
                    offset: "-16 -44",
                },
                shading: true,
            },
        });
        repaintFloatingActionButton();

        onIonViewWillLeave(() => {
            this.hiddenFloatBtn = false;
            this.toast.visible = false;
            this.setChinhSuaSoatVe(false);
            this.closeAllDialog();
        });
        this.emitter.on("hiddenFloatBtn", () => {
            this.hiddenFloatBtn = false;
        });
    },
    beforeMount() {
        localStorage.removeItem("ChoDaChon");
        localStorage.removeItem("ThongTinBanVe");
    },
    // mounted() {
    //     onIonViewWillEnter(async () => {
    //         this.setThongTinVe();
    //     });
    // },
    watch: {
        dangQuetMa: {
            handler: async function () {
                let rs = await this.kiemTraQrQuetMa();
                if (rs.check === true && rs.codeCheck == this.$t("StringCheck.QrVe")) {
                    this.setThongTinVe();
                }

                if (
                    rs.check === true &&
                    rs.codeCheck == this.$t("StringCheck.QrSDT") &&
                    this.$route.fullPath == "/tabs/tab3"
                ) {
                    this.toastThongBao("error", "Mã QR không hợp lệ!");
                }
            },
            immediate: true,
        },
        dialogDanhSachGhePhu() {
            if (!this.dialogDanhSachGhePhu) {
                this.NgatDOMPopup("ifDialogDanhSachGhePhu");
            }
        },
        PopupXacNhanBanVuotSoVeToiDa() {
            if (!this.PopupXacNhanBanVuotSoVeToiDa) {
                setTimeout(() => {
                    this.ifPopupXacNhanBanVuotSoVeToiDa = false;
                }, 300);
            }
        },
    },
};
</script>
<style scoped>
/* style tìm kiếm bằng sdt */
>>> .rounder {
    border-radius: 50px;
    box-shadow: inset 0 0 0 1px rgb(255 255 255) !important;
}
>>> .rounder .dx-button-content {
    position: fixed;
    right: 6px;
    height: auto;
}
>>> .rounder .dx-texteditor-input-container > div {
    color: white;
    top: 0px !important;
    left: 16px;
}
>>> .rounder .dx-texteditor-input-container > input {
    padding: 16px 12px 18px !important;
    color: white;
}
>>> .hidden-input {
    box-shadow: unset !important;
}
>>> .hidden-input .dx-texteditor-input-container {
    display: none !important;
}
>>> .nut-chuthich-style.dx-button-has-text {
    min-width: unset !important;
}
>>> .nut-chuthich-style .dx-button-content {
    padding: 0 !important;
}
>>> .nut-chuthich-style.nut-trangthai span {
    color: white;
}
>>> .chuthich-border {
    border: 1px solid #dadce0;
}
>>> .fab-float-button .dx-button {
    border-radius: 50px;
}
>>> .custom-icon-QR i.dx-icon {
    font-size: 20px;
}
.SoDoGhe {
    margin: 0 auto;
    width: calc((100vh - 36px - 48px - 48px) / 1.618);
    max-width: calc(100vw - 32px);
}
</style>
<style scoped lang="scss">
.fab-float-button {
    box-shadow: $box_shadow;
    border-radius: 50px;
    width: max-content;
    // height: 32px;
    position: fixed;
    right: 16px;
    bottom: 8px;
    z-index: 10;
    background-color: white;
}
.fab-float-button:active {
    opacity: 0.7;
}
@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .fab-float-button {
        bottom: 48px;
    }
}
</style>
