<template>
    <div
        class="chuthich-style boundary shadow"
        :class="drawer ? 'pr-2' : ''"
        :style="`width:${!drawer ? '36px' : '250px'};transition: width 0.2s`"
        @click="drawer = !drawer"
    >
        <div class="row align-center justify-start">
            <DxButton
                :active-state-enabled="false"
                :focus-state-enabled="false"
                :hover-state-enabled="false"
                type="normal"
                width="24px"
                height="24px"
                styling-mode="text"
                :icon="`mdi mdi-chevron-${drawer ? 'left' : 'right'}`"
                class="nut-chuthich-style clear-rounder text-xs-center"
            />
        </div>
        <!-- <div
            class="row align-center pt-2"
            v-for="(item, index) in data.KieuCho"
            :key="index"
        >
            <div class="shrink">
                <DxButton
                    :active-state-enabled="false"
                    :focus-state-enabled="false"
                    :hover-state-enabled="false"
                    :text="item.kyHieu"
                    type="normal"
                    styling-mode="text"
                    class="
                        nut-chuthich-style
                        clear-rounder
                        text-xs-center
                        chuthich-border
                    "
                    width="36px"
                    height="36px"
                />
            </div>
            <div class="grow pl-2" v-if="drawer">
                {{ item.tenKieuCho || "" }}
            </div>
        </div> -->
        <div
            style="
                overflow-y: auto;
                overflow-x: hidden;
                height: calc(100% - 136px);
            "
        >
            <div
                class="row align-center pt-2"
                v-for="(item, index) in data.DanhSachTrangThaiCho"
                :key="index"
            >
                <div class="shrink">
                    <DxButton
                        :active-state-enabled="false"
                        :focus-state-enabled="false"
                        :hover-state-enabled="false"
                        :text="item.soLuong + '' || '0'"
                        type="normal"
                        styling-mode="text"
                        width="24px"
                        height="24px"
                        class="
                            nut-chuthich-style
                            clear-rounder
                            nut-trangthai
                            text-xs-center
                        "
                        :class="item.idTrangThai == 1 ? 'chuthich-border' : ''"
                        :style="`background-color:${item.maMau};border-radius:4px;`"
                    />
                </div>
                <div
                    class="grow pl-2"
                    v-if="drawer"
                    style="white-space: nowrap"
                >
                    {{ `${item.tenTrangThai}: ${item.soLuong}` }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ChoNgoi } from "../../class/PhuongTien";
import { ref, reactive } from "vue";
import { DxButton } from "devextreme-vue";
export default {
    components: {
        DxButton,
    },
    props: {
        reload: { type: Number, default: 0 },
    },
    setup() {
        let drawer = ref(false);
        let data = reactive({ DanhSachTrangThaiCho: [], KieuCho: [] });
        return { drawer, data };
    },
    computed: {
        ...mapGetters("Ve", ["chuyenDiGanDay"]),
    },
    methods: {
        async getData() {
            //Lấy ds kiểu chỗ
            let DanhSachKieuCho = await new ChoNgoi().layDanhSachKieuCho();
            this.data.KieuCho = DanhSachKieuCho || [];
            //Lấy ds trạng thái chỗ
            let DanhSachTrangThaiCho =
                await new ChoNgoi().layDanhSachTrangThaiCho(
                    this.chuyenDiGanDay.guidChuyenDi
                );
            this.data.DanhSachTrangThaiCho = DanhSachTrangThaiCho || [];
        },
    },
    async created() {
        this.getData();
    },
};
</script>
<style lang="scss" scoped>
.chuthich-style {
    max-height: calc(100% - 48px);
    height: auto;
    position: absolute;
    left: 4px;
    top: 36px;
    padding: 4px;
    padding-bottom: 8px;
    border-radius: 8px;
    background-color: white;
    z-index: 1;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: 4px solid #fff;
}
</style>
<style scoped>
>>> .nut-chuthich-style.dx-button-has-text {
    min-width: unset !important;
}
>>> .nut-chuthich-style .dx-button-content {
    padding: 0 !important;
}
>>> .nut-chuthich-style.nut-trangthai span {
    color: white;
}
>>> .chuthich-border {
    border: 1px solid #dadce0;
}
</style>
