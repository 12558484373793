<template>
    <div
        class="row align-center pt-2"
        v-for="(item, index) in DanhSachTrangThaiChoNgoi"
        :key="index"
    >
        <div class="shrink">
            <DxButton
                :active-state-enabled="false"
                :focus-state-enabled="false"
                :hover-state-enabled="false"
                :text="item.soLuong + '' || '0'"
                type="normal"
                styling-mode="text"
                width="36px"
                height="36px"
                class="
                    clear-rounder
                    nut-trangthai
                    text-xs-center
                    nut-chuthich-style
                "
                :class="classButton(item)"
                :style="`background-color:${item.maMau};border-radius:4px;`"
            />
        </div>
        <div class="grow pl-2" v-if="drawer" style="white-space: nowrap">
            {{ `${item.tenTrangThai}: ${item.soLuong}` }}
        </div>
    </div>
    <div class="pt-2"></div>
    <div
        class="row align-center pt-2"
        v-for="(item, index) in SoLuongVeBanTheoLoaiDiemBan"
        :key="index"
    >
        <div class="grow" style="white-space: nowrap">
            {{ `${item.moTa}: ${item.soLuong}` }}
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ChoNgoi } from "../../../class/PhuongTien";
import { ref, reactive } from "vue";
import { DxButton } from "devextreme-vue";
export default {
    components: {
        DxButton,
    },
    props: {
        reload: { type: Number, default: 0 },
        DanhSachTrangThaiChoNgoi: { type: Array, default: () => [] },
        SoLuongVeBanTheoLoaiDiemBan: { type: Array, default: () => [] },
    },
    setup() {
        let drawer = ref(true);
        let data = reactive({ DanhSachTrangThaiCho: [], KieuCho: [] });
        return { drawer, data };
    },
    computed: {
        ...mapGetters("Ve", ["chuyenDiGanDay"]),
    },
    methods: {
        classButton(item) {
            let tmpClass = "";
            if (item.idTrangThai == 1) {
                tmpClass += "chuthich-border";
            }
            if (item.maMau == "#FFFFFF") {
                tmpClass += " btn-color-black ";
            } else {
                tmpClass += " btn-color-white ";
            }
            return tmpClass;
        },
    },
};
</script>
<style scoped>
>>> .nut-chuthich-style.dx-button-has-text {
    min-width: unset !important;
}
>>> .nut-chuthich-style .dx-button-content {
    padding: 0 !important;
}
>>> .btn-color-white.nut-trangthai span {
    color: white;
}
>>> .btn-color-black.nut-trangthai span {
    color: rgba(0, 0, 0, 0.87);
}
>>> .chuthich-border {
    border: 1px solid #dadce0;
}
</style>
