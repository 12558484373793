<template>
    <!-- <div style="height: auto; max-height: 244px"> -->
    <div class="row justify-center font-20 font-medium mb-1">Khách mua vé ghế phụ</div>
    <DxTextBox
        v-model="textTimKiem"
        placeholder="Tìm kiếm"
        styling-mode="outlined"
        height="36px"
        :showClearButton="true"
        :elementAttr="{ class: 'custom-editor-search' }"
    />
    <div class="row font-italic font-12 mt-1 mb-2 ml-2">
        Nhập Điểm xuống, SĐT, Tên người mua, Trạng thái để tìm kiếm! (Vd: Đã lên xe, Chưa
        lên xe, ...)
    </div>
    <DxList
        :dataSource="{
            store: DanhSachVeGhePhu,
            paginate: true,
            requireTotalCount: true,
            pageSize: 10,
            filter: dataFilter,
        }"
        page-load-mode="nextButton"
        height="288px"
        item-template="list-item"
    >
        <!-- :searchExpr="[
            'diemXuong',
            'hoTen',
            'tenTrangThaiHanhKhach',
            'idTrangThaiHanhKhach',
            'soDienThoai',
        ]"
        :searchEnabled="true"
        :searchEditorOptions="{
            stylingMode: 'outlined',
            height: 36,
            elementAttr: {
                class: 'custom-editor-search',
            },
        }" -->
        <template #list-item="{ data, index }">
            <div class="row card-ticket mr-2" style="border-bottom: 1px solid #ccc">
                <div class="grow">
                    <div :class="rowText">
                        <div>Số điện thoại:</div>
                        <div class="font-medium color-success">
                            {{ "\xa0" + data.soDienThoai }}
                        </div>
                    </div>
                    <div :class="rowText">
                        <div>Giá vé:</div>
                        <div class="font-medium color-error">
                            {{ "\xa0" + $MoneyFormat(data.giaVe) }}đ
                        </div>
                    </div>
                    <div
                        :class="rowText"
                        v-if="
                            ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                $t(
                                    'MaCauHinhDoanhNghiep.DoanhNghiepSuDungHoaDonBanHangCoGiamTru',
                                )
                            ] == 'true'
                        "
                    >
                        <div>Giá vé sau giảm trừ:</div>
                        <div class="font-medium color-warning">
                            {{ "\xa0" + $MoneyFormat(data.thanhTien) }}đ
                        </div>
                    </div>
                    <div :class="rowText">
                        <div>Điểm xuống:</div>
                        <div class="font-medium color-primary">
                            {{ "\xa0" + data.diemXuong }}
                        </div>
                    </div>
                    <div class="row">
                        <div>Trạng thái:</div>
                        <div
                            class="font-medium"
                            :style="`color: ${data.maMauTrangThaiHanhKhach}`"
                        >
                            {{ "\xa0" + data.tenTrangThaiHanhKhach }}
                        </div>
                    </div>
                </div>
                <div class="shrink">
                    <div class="row align-center fill-height">
                        <div class="xs12">
                            <div class="row justify-center">
                                <DxButton
                                    icon="mdi mdi-printer"
                                    type="default"
                                    styling-mode="text"
                                    @click="InLaiVe(data)"
                                />
                                <DxButton
                                    v-if="
                                        data.idTrangThaiHanhKhach ==
                                        $t('TrangThaiKhachGhePhu.DaLenXe')
                                    "
                                    icon="mdi mdi-transfer-down"
                                    type="success"
                                    styling-mode="text"
                                    @click="XacNhanKhachXuongXe(data.maDatCho)"
                                />
                                <DxButton
                                    v-if="
                                        data.idTrangThaiHanhKhach ==
                                        $t('TrangThaiKhachGhePhu.ChuaLenXe')
                                    "
                                    icon="mdi mdi-transfer-up"
                                    type="default"
                                    styling-mode="text"
                                    @click="XacNhanKhachLenXe(data)"
                                />
                            </div>
                            <div class="row justify-center mt-1">
                                <DxButton
                                    v-if="
                                        data.idTrangThaiHanhKhach ==
                                        $t('TrangThaiKhachGhePhu.ChuaLenXe')
                                    "
                                    icon="mdi mdi-account-off-outline"
                                    type="danger"
                                    styling-mode="text"
                                    @click="XacNhanKhachLoChuyen(data)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </DxList>
</template>

<script>
import { DxList, DxButton, DxTextBox } from "devextreme-vue";
import ChuyenDi from "../../../class/ChuyenDi";
import DonHang from "../../../class/DonHang";
import { mapGetters, mapState } from "vuex";
export default {
    components: {
        DxTextBox,
        DxList,
        DxButton,
    },
    props: {},
    data() {
        return {
            rowText: "row mb-1",
            textTimKiem: null,
            DanhSachVeGhePhu: [],
        };
    },
    computed: {
        ...mapState({
            ToaDo: (state) => state.ThongTin.ToaDo,
        }),
        ...mapGetters("Ve", ["chuyenDiGanDay"]),
        dataFilter() {
            // let trangThai =
            //     this.filter.TrangThai && this.filter.TrangThai != 0
            //         ? ["idTrangThai", "=", parseInt(this.filter.TrangThai)]
            //         : null;
            let timKiem = this.textTimKiem
                ? [
                      ["diemXuong", "contains", this.textTimKiem],
                      "or",
                      ["hoTen", "contains", this.textTimKiem],
                      "or",
                      ["tenTrangThaiHanhKhach", "contains", this.textTimKiem],
                      "or",
                      ["soDienThoai", "contains", this.textTimKiem],
                  ]
                : null;
            let filter = timKiem || null;
            return filter;
        },
        ChiTietCauHinhDoanhNghiepTheoMaCauHinh() {
            return this.$store.state.DVVTLenh.ChiTietCauHinhDoanhNghiepTheoMaCauHinh;
        },
    },
    methods: {
        GioXuatBen(date) {
            if (date) {
                let tmpDate = new Date(date);
                let hours =
                    tmpDate.getHours() < 10
                        ? "0" + tmpDate.getHours()
                        : tmpDate.getHours();
                let minutes =
                    tmpDate.getMinutes() < 10
                        ? "0" + tmpDate.getMinutes()
                        : tmpDate.getMinutes();
                let tmpStr = hours + ":" + minutes;
                return tmpStr;
            }
            return "";
        },
        InLaiVe(ThongTinKhachHang) {
            if (ThongTinKhachHang.daPhatHanh == false) {
                let query = {
                    guidChuyenDi: this.chuyenDiGanDay.guidChuyenDi,
                    maDatCho: ThongTinKhachHang.maDatCho,
                };
                this.PhatHanhHoaDon(query, ThongTinKhachHang.maDatCho);
            } else {
                this.layThongTinVe(ThongTinKhachHang.maDatCho);
            }
        },
        async PhatHanhHoaDon(query, maDatCho) {
            try {
                let donHang = new DonHang();
                let rs = await donHang.phatHanhVeTheoMaDatCho(query);
                if (!rs.Data.status) {
                    return this.$emit("thongBaoLoi", rs.Data.message);
                }
            } catch (error) {
                this.$emit("thongBaoLoi", error);
            }
            this.layThongTinVe(maDatCho);
        },
        async layThongTinVe(maGiaoDich) {
            try {
                let rs = await this.$AppClass.InVe(
                    this.$i18n.t("url.InVeTheoMaDatCho"),
                    "maDatCho",
                    maGiaoDich,
                );
                if (rs == "error") {
                    return this.$emit("thongBaoLoi", "In vé thất bại!");
                }
                this.$stopLoading;
            } catch (error) {
                this.$stopLoading;
                this.error = true;
                console.error(error);
            }
        },
        async XacNhanKhachXuongXe(maDatCho) {
            try {
                let chuyenDi = new ChuyenDi();
                chuyenDi.guidXe = this.chuyenDiGanDay.guidXe;
                chuyenDi.maChuyenDi = this.chuyenDiGanDay.maChuyenDi;
                let ketQua = await chuyenDi.ghePhu_XacNhanXuongXe(
                    maDatCho,
                    this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                );
                if (!ketQua.Data.status) {
                    return this.$emit("thongBaoLoi", ketQua.Data.message);
                }
                this.$Helper.ThongBaoToast(
                    "success",
                    "Xác nhận hành khách xuống xe thành công!",
                );
                this.layDanhSachMuaVeGhePhu();
            } catch (error) {
                this.$emit("thongBaoLoi", error);
            }
        },
        async XacNhanKhachLenXe(data) {
            try {
                let query = {
                    maChuyenDi: this.chuyenDiGanDay.maChuyenDi,
                    maDatCho: data.maDatCho,
                    guidXe: this.chuyenDiGanDay.guidXe,
                    toaDo: this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                };
                let rs = await this.$Events.RequestPost({
                    keyRequest: "XacNhanKhachLenXe",
                    ServicesBE: "DoiSoat",
                    url: this.$t("url.SoatVe"),
                    dataPost: query,
                    msgSuccess: "Xác nhận hành khách lên xe thành công!",
                    msgError: "Xác nhận hành khách lên xe thất bại!",
                    msgCatch: "Xác nhận hành khách lên xe thất bại!",
                    typeNotificationSuccess: "Toast",
                    typeNotificationError: "Toast",
                });
                this.layDanhSachMuaVeGhePhu();
            } catch (error) {
                this.$emit("thongBaoLoi", error);
            }
        },
        async XacNhanKhachLoChuyen(data) {
            try {
                let query = {
                    maChuyenDi: this.chuyenDiGanDay.maChuyenDi,
                    maDatCho: data.maDatCho,
                    guidXe: this.chuyenDiGanDay.guidXe,
                    toaDo: this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                };
                let rs = await this.$Events.RequestPost({
                    keyRequest: "XacNhanKhachLoChuyen",
                    ServicesBE: "DoiSoat",
                    url: this.$t("url.XacNhanKhachLoChuyen"),
                    dataPost: query,
                    msgSuccess: "Xác nhận hành khách lỡ chuyến thành công!",
                    msgError: "Xác nhận hành khách lỡ chuyến thất bại!",
                    msgCatch: "Xác nhận hành khách lỡ chuyến thất bại!",
                    typeNotificationSuccess: "Toast",
                    typeNotificationError: "Toast",
                });
                this.layDanhSachMuaVeGhePhu();
            } catch (error) {
                this.$emit("thongBaoLoi", error);
            }
        },
        async layDanhSachMuaVeGhePhu() {
            try {
                this.DanhSachVeGhePhu = (
                    await this.$store.dispatch(
                        "NhanVienBanVe/LayDanhSachKhachNgoiGhePhu",
                        this.chuyenDiGanDay.guidChuyenDi,
                    )
                ).Store;
            } catch (error) {
                this.$emit("thongBaoLoi", error);
            }
        },
    },
    created() {
        this.layDanhSachMuaVeGhePhu();
    },
};
</script>

<style scoped>
.no-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* .card-ticket {
    box-shadow: 0px 2px 9px #ccc;
} */
.custom-editor-search :deep(.dx-placeholder) {
    display: flex;
    align-items: center;
}
/* .custom-editor-search :deep(.dx-placeholder::before) {
    padding: unset;
    padding-left: 12px;
} */
</style>
